import {
  chakra,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useOutsideClick,
} from '@chakra-ui/react';
import _ from 'lodash';
import { InferProps, instanceOf, number, oneOf, shape } from 'prop-types';
import React, { MouseEvent, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { FwArticle, FwIcon, FwSpinner, useFwTheme } from 'components/base';
import { FwMaskCommonProps } from 'core/model/props/FwMask.props';
import {
  dateFormats,
  jsDateFromString,
  jsDateToString,
  initDateTime,
} from 'core/utils/date';
import utils from 'core/utils/utils';

import { DateViewType } from '../FwMask.structures';
import { TimelineProgress } from './components';
import {
  dateIsBetween,
  findConflict,
  getDateDiff,
  getHour,
  maskRowsToTimelineData,
  setLabelsOverlapIndices,
} from './FwMask.Timeline.helpers';
import TimelineTh, { FwTimelineThProps } from './FwMask.Timeline.Th';

const TimelineTable = ({
  endView,
  loading,
  maskStructure,
  maskRows,
  selectedDate,
  selectedView,
  slotMultiplier,
  startView,
  ...props
}: TimelineTableProps) => {
  const { t } = useTranslation();

  // todo wip#585 refactor with Row.tsx
  const {
    accent,
    bg: background,
    _active: { bg },
    _hover,
    _disabled: { boxShadowColor },
  } = useFwTheme(0.9);

  const timelineTableRef = useRef();

  const [activeItem, setActiveItem] = useState(undefined);
  const [timeline, setTimeline] = useState([]);
  const [headerData, setHeaderData] = useState({
    firstHeaderCells: [] as FwTimelineThProps[],
    secondHeaderCells: [] as FwTimelineThProps[],
    slots: 0,
  });
  const [expandedRow, setExpandedRow] = useState<{
    rowIndex: number;
    overlapDegree: number;
  }>();

  const timeoutRef = useRef(null);
  const betweenDateRef = useRef(
    dateIsBetween(new Date(), selectedDate.startDate, selectedDate.endDate)
  );
  const [now, setNow] = useState(new Date());

  // reset active item on outside click
  useOutsideClick({
    ref: timelineTableRef,
    handler: () => handleRowClick(undefined),
  });

  // show data in timeline
  useEffect(() => {
    if (maskStructure && maskRows) {
      const rawTimelineData = maskRowsToTimelineData(
        maskStructure,
        maskRows,
        t
      );

      // pass all time values to override all time parts
      const currentDateStartView = initDateTime(
        selectedDate.startDate,
        startView,
        0,
        0,
        0
      );
      const currentDateEndView = initDateTime(
        selectedDate.endDate,
        endView,
        0,
        0,
        -1
      );

      // only keep events within the timeline view limits: [startView, endView]
      _.each(rawTimelineData, (d) => {
        d.labels = _.filter(
          d.labels,
          (lbl) =>
            lbl.end > currentDateStartView && lbl.start < currentDateEndView
        );
      });

      // remove timeline rows without events
      const timelineData = _.filter(
        rawTimelineData,
        (d) => d.labels.length > 0
      );

      setTimeline(timelineData);
    }
  }, [endView, maskStructure, maskRows, selectedDate, startView, t]);

  // build table headers
  useEffect(() => {
    if (maskStructure) {
      const timeStart = getHour(startView, 0, 23, 9);
      const timeEnd = getHour(endView, 1, 24, 18);

      const firstHeaderCells: FwTimelineThProps[] = [];
      const secondHeaderCells: FwTimelineThProps[] = [];

      const timeSlots = (timeEnd - timeStart) * 2 * slotMultiplier;
      const daySlots =
        getDateDiff(selectedDate.startDate, selectedDate.endDate) + 1;

      let index = 1;

      for (let d = 0; d < daySlots; d++) {
        const focusDate = _.cloneDeep(selectedDate.startDate);
        focusDate.setDate(focusDate.getDate() + d);

        if (selectedView === DateViewType.month) {
          const text = jsDateToString(focusDate, dateFormats.date);
          // fill secondHeaderCells
          secondHeaderCells.push({
            colSpan: 2,
            date: focusDate,
            text,
            type: dateFormats.date,
          });

          const nextDate = _.cloneDeep(selectedDate.startDate);
          nextDate.setDate(nextDate.getDate() + d + 1);

          const isSameMonth = nextDate.getMonth() === focusDate.getMonth();

          if (!isSameMonth || d === daySlots - 1) {
            const month = focusDate.getMonth() + 1;
            const text =
              `${focusDate.getFullYear()}` +
              '-' +
              (month.toString().length == 1 ? '0' + `${month}` : `${month}`);

            // fill firstHeaderCells
            firstHeaderCells.push({
              colSpan: index * 2,
              date: focusDate,
              text,
              type: dateFormats.monthYear,
            });

            // reset index
            index = 1;
          } else {
            index++;
          }
        } else {
          // fill firstHeaderCells
          if (selectedView === DateViewType.week) {
            const text = jsDateToString(focusDate, dateFormats.date);
            firstHeaderCells.push({
              colSpan: timeSlots,
              date: focusDate,
              text,
              type: dateFormats.date,
            });
          }

          // fill secondHeaderCells
          for (let i = timeStart; i < timeEnd; i++) {
            for (let j = 0; j < slotMultiplier; j++) {
              const min = (j / slotMultiplier) * 60;

              const stringHours = i === 0 ? '0' : utils.toHourString(i);
              const stringMin =
                min.toString().length == 1 ? '0' + `${min}` : `${min}`;

              const text = jsDateToString(
                jsDateFromString(
                  `${stringHours}:${stringMin}`,
                  dateFormats.isoTime
                ),
                dateFormats.time
              );

              const tempDate = _.cloneDeep(focusDate);
              tempDate.setHours(parseInt(stringHours), parseInt(stringMin));

              secondHeaderCells.push({
                colSpan: 2,
                date: tempDate,
                text,
                type: dateFormats.time,
              });
            }
          }
        }
      }

      // set slots
      const slots =
        selectedView === DateViewType.month
          ? 2 * daySlots
          : timeSlots * daySlots;

      setHeaderData({ firstHeaderCells, secondHeaderCells, slots });
    }
  }, [
    accent,
    endView,
    maskStructure,
    selectedDate,
    selectedView,
    slotMultiplier,
    startView,
  ]);

  // update display every minute when viewing current day
  useEffect(() => {
    const currentDayIsViewed = betweenDateRef.current;

    if (currentDayIsViewed) {
      // cancel previous timeout
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      // build new 1-minute timeout
      timeoutRef.current = setTimeout(() => {
        setNow(new Date());
      }, 60000);
    }

    return () => {
      // prevent state update after unmount
      clearTimeout(timeoutRef.current);
    };
  }, [now]);

  const handleRowClick = (key: string) => {
    setActiveItem(key);
  };

  const { slots, firstHeaderCells, secondHeaderCells } = headerData;

  const hideExpand = maskStructure?.view?.hideExpand;
  const hideConflictWarning = maskStructure?.view?.hideConflictWarning;

  // todo #585 refactor styling?
  const tableStyle = {
    size: 'sm',
    sx: {
      'thead, tbody, tr, th, td': { border: 'none' },
      'td:not(:first-of-type)': { paddingLeft: 0, paddingRight: 0 },
    },
  };

  return (
    <Table ref={timelineTableRef} {...tableStyle}>
      <Thead>
        <Tr>
          <Th rowSpan={2} />
        </Tr>
        <Tr>
          {_.map(firstHeaderCells, (fhc, i) => (
            <TimelineTh key={i} {...fhc} now={now} />
          ))}
        </Tr>
        <Tr>
          {/* set width near 0 to allow table to collapse first column until it fits content */}
          <Th rowSpan={2} width="0.01%" />
        </Tr>
        <Tr>
          {_.map(secondHeaderCells, (fhc, i) => (
            <TimelineTh key={i} {...fhc} now={now} />
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {loading && (
          <Tr>
            <Td colSpan={slots}>
              <FwSpinner />
            </Td>
          </Tr>
        )}
        {!loading && _.isEmpty(timeline) && (
          <Tr>
            <Td colSpan={slots}>{t('No records found')}</Td>
          </Tr>
        )}
        {!loading &&
          timeline.length > 0 &&
          _.map(timeline, (d, index) => {
            const active = activeItem === d.name;

            // todo refactor click logic with Row.tsx?
            const handleClick = () => handleRowClick(d.name);

            const handleMouseDownUp = (e: MouseEvent<HTMLTableRowElement>) => {
              // if right click
              if (e.button === 2) {
                handleClick();
              }
            };

            // when row is expanded, it has a overlap degree
            const overlapDegree =
              expandedRow?.rowIndex === index
                ? expandedRow.overlapDegree
                : undefined;

            // max overlap index will become the overlap degree once the row is expanded
            const maxOverlapIndex = !hideExpand
              ? setLabelsOverlapIndices(d.labels)
              : 0;

            const toggleExpandRow = () => {
              setExpandedRow(
                overlapDegree
                  ? undefined
                  : { rowIndex: index, overlapDegree: maxOverlapIndex }
              );
            };

            // find conflicted labels
            const firstConflictedLabel = !hideConflictWarning
              ? findConflict(d.labels, slots, secondHeaderCells, selectedView)
              : undefined;

            // todo wip#585 refactor with Row.tsx
            const rowStyle = {
              bg: active ? bg : undefined,
              boxShadow: `inset 0 -1px 0 0 ${boxShadowColor}`,
              height: overlapDegree
                ? `calc(5px * (${overlapDegree} + 2) + 24px * (${overlapDegree} + 1))`
                : undefined,
              _hover:
                // (prevent hover from overriding active style, despite :hover pseudo-element)
                _.merge(_hover, active ? { bg } : {}),
            };

            return (
              <Tr
                {...rowStyle}
                key={d.name}
                onMouseDown={handleMouseDownUp}
                onMouseUp={handleMouseDownUp}
                onClick={handleClick}
              >
                <Td
                  position="sticky"
                  left={0}
                  background={background}
                  zIndex={5}
                  boxShadow={`inset 0 -1px 0 0 ${boxShadowColor}`}
                >
                  {d.name ? (
                    d.name
                  ) : (
                    // prevent cell height collapse when no data
                    <chakra.b color="transparent" visibility="hidden">
                      ...
                    </chakra.b>
                  )}
                  {!hideConflictWarning && firstConflictedLabel && (
                    <chakra.span
                      visibility={overlapDegree ? 'hidden' : undefined}
                    >
                      <FwIcon
                        atInlineEnd
                        inline
                        name="RiAlertFill"
                        color="orange"
                        tooltip={
                          firstConflictedLabel.text ? (
                            <FwArticle
                              small
                              content={[
                                t('This item might be difficult to see:'),
                                firstConflictedLabel.text,
                              ]}
                            />
                          ) : null
                        }
                      />
                    </chakra.span>
                  )}
                  {!hideExpand && maxOverlapIndex > 0 && (
                    <chakra.span bg={background} position="absolute" right={0}>
                      <chakra.div cursor="pointer" onClick={toggleExpandRow}>
                        <FwIcon
                          primary
                          name={
                            overlapDegree
                              ? 'RiArrowUpSLine'
                              : 'RiArrowDownSLine'
                          }
                        />
                      </chakra.div>
                    </chakra.span>
                  )}
                </Td>
                <TimelineProgress
                  {...props}
                  data={d}
                  endView={endView}
                  index={index}
                  now={now}
                  overlapDegree={overlapDegree}
                  selectedDate={selectedDate}
                  selectedView={selectedView}
                  setExpandedRow={setExpandedRow}
                  slotMultiplier={slotMultiplier}
                  slots={slots}
                  startView={startView}
                />
              </Tr>
            );
          })}
      </Tbody>
    </Table>
  );
};

const timelineTablePT = {
  endView: number,
  selectedDate: shape({
    endDate: instanceOf(Date),
    startDate: instanceOf(Date),
  }),
  selectedView: oneOf(_.values(DateViewType)),
  slotMultiplier: number,
  startView: number,
};

type TimelineTableProps = InferProps<typeof timelineTablePT> &
  FwMaskCommonProps;

TimelineTable.propTypes = timelineTablePT;

export default TimelineTable;
